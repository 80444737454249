body {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.App {
    width: 100%;
}

main {
    margin: 30px 40px 30px 190px;
    padding-bottom: 50px;
}

.page-header {
    border-bottom: 1px solid lightgrey;
}

@media only screen and (max-width: 768px) {
    main {
        margin: 20px 10px;
        /* margin: 20px 0px; */
    }

    #sidebar {
        display: none;
    }
}

/* semantic-ui-react */
.ui.selection.dropdown {
    min-width: 10em;
}
